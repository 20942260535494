import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { printAbsAmount } from "@shared/functions";
import { printCuadreHandler } from "@shared/functions/print";
import DatePicker from "@shared/components/DatePicker";
import RenderCuadreData from "@shared/components/RenderCuadreData";
import UpdateBankAmount from "../components/UpdateBankAmount";
import UpdateEfectivoAmount from "../components/UpdateEfectivoAmount";
import Loading from "@shared/components/Loading";
import { UseCollected, UseCuadre, UseEntregas, UseGastos, UseRoute } from "@shared/server/fetcher";
import { AiFillBank } from "react-icons/ai";
import LinearProgressWithLabel from "@shared/components/LinearProgressWithLabel";
import NoInternetContent from "@shared/components/NoInternetContent";
import RenderUserRestriction from "../components/RenderUserRestriction";

const Cuadre = ({ history }) => {
  const { state, printCuadreData, updateEfectivoAmount, updateBankAmount } = useContext(Context);
  const { route: stateRoute, user, restritec_users } = state;

  const { data: route, isLoading: routeLoading } = UseRoute(stateRoute.creditor_id, user.user_id);
  const [currentDate, setCurrentDate] = useState(null);
  const { data, isLoading, mutate } = UseCuadre(stateRoute.creditor_id, currentDate ?? route?.collect_week_date);

  const { data: collected, isLoading: paymentLoading } = UseCollected(
    route?.creditor_id,
    data?.current_week,
    data?.range_date
  );

  const { data: entregas, isLoading: entregasLoading } = UseEntregas(
    route?.creditor_id,
    data?.current_week,
    data?.range_date
  );

  const { data: gastos, isLoading: gastosLoading } = UseGastos(
    route?.creditor_id,
    data?.current_week,
    data?.range_date
  );

  useEffect(() => {
    if (route) setCurrentDate(route?.collect_week_date);
    //eslint-disable-next-line
  }, [route]);

  if (restritec_users.includes(+user.user_id)) return <RenderUserRestriction />;

  if (isLoading || routeLoading) return <Loading />;
  if (!data) return <NoInternetContent />;

  if (data.cuadre_id === "noCuadreFound")
    return (
      <div className="container m-2">
        <Typography variant="h6">No Cuadre encontrado!</Typography>
        <DatePicker setCurrentDate={setCurrentDate} date={currentDate} route={route} />
      </div>
    );

  const handleBankUpdate = async (data) => {
    await updateBankAmount(data);
    mutate();
  };

  const handleEfectivoUpdate = async (data) => {
    await updateEfectivoAmount(data);
    mutate();
  };

  const transferedSum = collected?.reduce((acc, obj) => {
    return obj.paymentType !== "cash" ? acc + +obj.paymentAmount : acc;
  }, 0);

  const cashPaymentsSum = collected?.reduce((acc, obj) => {
    return obj.paymentType === "cash" ? acc + +obj.paymentAmount : acc;
  }, 0);

  const totalMoraSum = collected?.reduce((acc, obj) => {
    return acc + +obj.paymentMora;
  }, 0);

  const gastosSum = gastos?.reduce((acc, obj) => {
    return acc + +obj.amount;
  }, 0);

  const actaNoctarialSum = entregas?.reduce((acc, obj) => {
    return acc + +obj.actanotarial;
  }, 0);

  const newLoansSum = entregas?.reduce((acc, obj) => {
    return acc + +obj.amount;
  }, 0);

  const moraXDepositts = collected?.reduce((acc, obj) => {
    return obj.paymentType === "deposit" ? acc + +obj.paymentMora : acc;
  }, 0);

  const cashMoney =
    data.bank_amount * 1 + actaNoctarialSum + cashPaymentsSum + totalMoraSum - gastosSum - newLoansSum - moraXDepositts;

  const progress =
    data.totalCuentaXcobrarAllSum > 0
      ? ((data.totalCuentaXcobrarAllSum - data.totalCuentaXcobrarSum) / data.totalCuentaXcobrarAllSum) * 100
      : 0;

  return (
    <div className="container mt-3 mb-5">
      <Typography variant="h6" component="h4">
        Estado de la ruta ({data.cuentaXcobrarAll?.length}): {printAbsAmount(data.totalCuentaXcobrarAllSum)}
      </Typography>

      <DatePicker setCurrentDate={setCurrentDate} date={currentDate} route={route} />
      <LinearProgressWithLabel value={progress} />
      <UpdateBankAmount
        history={history}
        cashMoney={cashMoney}
        print={printCuadreHandler}
        handleBankUpdate={handleBankUpdate}
        route={{ ...route, today_date: currentDate }}
        data={data}
      />

      <RenderCuadreData
        data={data}
        collected={{
          data: collected,
          totalCash: cashPaymentsSum,
          totalMora: totalMoraSum,
          totalDeposit: transferedSum,
          loading: paymentLoading,
        }}
        gastos={{ data: gastos, total: gastosSum, loading: gastosLoading }}
        entregas={{ data: entregas, totalNewLoans: newLoansSum, totalActa: actaNoctarialSum, loading: entregasLoading }}
      />

      <Box component="div" p={1}>
        <Typography variant="body2">
          <span className="text-primary">Capital: {printAbsAmount(data.capitalSum)}</span>
          <span className="text-danger pl-5">Reditos: {printAbsAmount(data.interestSum)}</span>
        </Typography>
      </Box>

      <UpdateEfectivoAmount
        cashMoney={cashMoney}
        handleEfectivoUpdate={handleEfectivoUpdate}
        data={{ ...data, today_date: currentDate }}
        history={history}
        print={printCuadreData}
      />

      {transferedSum + moraXDepositts > 0 && (
        <Card variant="outlined" className="mt-1">
          <CardContent>
            <b>
              <span>
                <AiFillBank size="1.3em" /> Dinero de Transferencia: {printAbsAmount(transferedSum + moraXDepositts)}
              </span>
            </b>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default Cuadre;
